import {
  AUTH_TOKEN,
  BASE_DOMAIN,
  FIRST_LOGIN,
  PRODUCTION,
} from "@localization";
import Cookies from "js-cookie";

export const getAuthCookie = () => {
  return Cookies.get(AUTH_TOKEN, { path: "/" });
};

export const setSeverSideCookie = (token, callback = () => {}) => {
  process.env.NEXT_PUBLIC_NODE_ENV === PRODUCTION
    ? Cookies.set(AUTH_TOKEN, token, {
        expires: 30,
        domain: BASE_DOMAIN,
        path: "/",
      })
    : Cookies.set(AUTH_TOKEN, token, {
        expires: 30,
        path: "/",
      });
  callback();
};

export const removeServerSideCookie = (callback = () => {}) => {
  process.env.NEXT_PUBLIC_NODE_ENV === PRODUCTION
    ? Cookies.remove(AUTH_TOKEN, { domain: BASE_DOMAIN, path: "/" })
    : Cookies.remove(AUTH_TOKEN, { path: "/" });
  callback();
};

export const getFirstTimeLoginStatus = () => {
  return Cookies.get(FIRST_LOGIN) ?? null;
};

export const setFirstTimeLoginStatus = () => {
  Cookies.set(FIRST_LOGIN, FIRST_LOGIN, { expires: 30, path: "/" });
};

export const removeFirstTimeLoginStatus = () => {
  Cookies.remove(FIRST_LOGIN, { path: "/" });
};
