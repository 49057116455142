export const PRODUCTION = "production";
export const DEVELOPMENT = "development";
export const STAGING = "staging";
export const ISPROD = process.env.NEXT_PUBLIC_APP_ENV === PRODUCTION;
export const BASE_MARKETING_URL = ISPROD
  ? "https://www.unschool.in"
  : "https://staging.unschool.in";

export const BASE_LMS_URL = ISPROD
  ? "https://beta-learn.unschool.in"
  : "https://staging-learn.unschool.in";

export const BASE_CART_URL = ISPROD
  ? "https://www.unschool.in/checkout/"
  : "https://staging.unschool.in/checkout/";

export const HTTPS = "https://";

// Help / Support URL
export const SUPPORT_URL = "https://support.unschool.in/support/home";

// Pensil URL
export const PENSIL_URL = ISPROD
  ? "https://community-new.unschool.in/"
  : "https://unschool-test.pensil.so/";

// Pensil API Endpoint
export const PENSIL_API_ENDPOINT = ISPROD
  ? "http://api.pensil.in/api"
  : "http://api.pensil.so/api";

// Pensil Group ID
export const PENSIL_GROUP_ID = ISPROD
  ? "62306a73045e1948171c496d"
  : "626fb6fd758f44eb39c09f97";

// Redux
export const REDUX_PERSIST = "redux-persist";

//Marketing website URL
export const MARKETING_WEBSITE_URL = BASE_MARKETING_URL;

// Next JS Server Endpoints
export const NEXT_SERVER_ENDPOINT = "/api";
export const SET_COOKIE_ENDPOINT = NEXT_SERVER_ENDPOINT + "/login";
export const REMOVE_COOKIE_ENDPOINT = NEXT_SERVER_ENDPOINT + "/logout";

// Projects
export const SUBMIT_MINOR_PROJECT = "submit-minor-project";
export const SUBMIT_MAJOR_PROJECT = "submit-major-project";
export const MAJOR_PROJECT_TYPE = "major";
export const MINOR_PROJECT_TYPE = "minor";
export const JOB_PROGRAM_PROJECT_TYPE = "job_program";

// Syllabus
export const LESSON = "lesson";
export const CHAPTER = "chapter";
export const MODULE = "module";

//Hash By Key
export const HASH_BY_ID = "id";
export const HASH_BY_SLUG = "slug";

//Course Media
export const PDF = "pdf";
export const VIDEO = "video";
export const AUDIO = "audio";
export const ASSESSMENT = "assessment";

// Pagination
export const NEXT_PAGE = "next";
export const PREV_PAGE = "prev";

// Zoom
export const ZOOM_IN = "zoom-in";
export const ZOOM_OUT = "zoom-out";
export const ZOOM_RESET = "zoom-reset";

// Tribe
export const TRIBE_URL = "https://community.unschool.in/";

// Base Domain
export const BASE_DOMAIN = ".unschool.in";

//Auth
export const AUTH_TOKEN = "auth-token";
export const TOKEN = "token";
export const SET_COOKIE = "Set-Cookie";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const VERIFY_OTP = "verify-otp";
export const ADD_USER_DETAILS = "add-user-details";
export const FIRST_LOGIN = "__fl";

// Routes
export const ROOT_ROUTE = "/";
export const COURSES_ROUTE = "/courses";
export const NOTIFICATIONS_ROUTE = "/notifications";
export const AUTH_ROUTE = "/auth";
export const ACCESS_DENIED_ROUTE = "/403";
export const PAGE_NOT_FOUND_ROUTE = "/404";
export const SERVER_ERROR_ROUTE = "/500";
export const USER_PROFILE_ROUTE = "/user/profile";
export const INTERNSHIPS_ROUTE = "/internships";
export const COMMUNITY_ROUTE = "/community";
export const EVENTS_ROUTE = "/events";

//Dynamic Routes - These are used for conditional logics from these pages
export const COURSE_ROUTE = "/course";
export const SKILL_COURSE_ROUTE = "/skill-course";
export const JOB_COURSE_ROUTE = "/job-course";
export const SUBMIT_PROJECT_ROUTE = "/submit-project";

//S3 Image CDN paths
export const IMAGE_CDN_ENDPOINT =
  HTTPS + process.env.NEXT_PUBLIC_IMAGE_CDN_DOMAIN + "/assets";

//Common Folder path
export const COMMON_IMAGE_PATH = IMAGE_CDN_ENDPOINT + "/common";
export const AVATAR_IMAGE_PATH = COMMON_IMAGE_PATH + "/avatar.jpeg";
export const COURSE_CARD_IMAGE_PATH = COMMON_IMAGE_PATH + "/course-card.webp";
export const COURSE_CARD_IMAGE_PLACEHOLDER =
  COMMON_IMAGE_PATH + "/placeholder.png";

//Brand Folder path
export const BRAND_IMAGE_PATH = IMAGE_CDN_ENDPOINT + "/brand";
export const UNSCHOOL_IMAGE_PATH = BRAND_IMAGE_PATH + "/unschool-logo.png";

//Plan Types Folder path
export const PLAN_TYPES_IMAGE_PATH = IMAGE_CDN_ENDPOINT + "/plan-types";
export const BASIC_IMAGE_PATH = PLAN_TYPES_IMAGE_PATH + "/basic.png";
export const ADVANCE_IMAGE_PATH = PLAN_TYPES_IMAGE_PATH + "/advance.png";
export const PROFESSIONAL_IMAGE_PATH =
  PLAN_TYPES_IMAGE_PATH + "/professional.png";
export const PRO_IMAGE_PATH = PLAN_TYPES_IMAGE_PATH + "/pro.png";

//S3 SVG CDN paths
export const SVG_CDN_ENDPOINT = HTTPS + process.env.NEXT_PUBLIC_SVG_CDN_DOMAIN;
export const ERROR_SVG_PATH = SVG_CDN_ENDPOINT + "/broken.svg";
export const COURSES_SVG_PATH = SVG_CDN_ENDPOINT + "/courses.svg";
export const PAGE_NOT_FOUND_SVG_PATH = SVG_CDN_ENDPOINT + "/lost.svg";
export const NOTIFICATIONS_SVG_PATH =
  SVG_CDN_ENDPOINT + "/notifications-read.svg";
export const UPGRADE_SVG_PATH = SVG_CDN_ENDPOINT + "/upgrade.svg";
export const AUDIO_LESSON_SVG_PATH = SVG_CDN_ENDPOINT + "/audio-lesson.svg";

//Notes
export const ALL_LESSONS_AND_ASSESSMENTS = "All lessons and assessments";
export const CURRENT_LESSON_OR_ASSESSMENT = "Current lesson or assessment";

//Projects
export const UNDER_REVIEW = "Under Review";
export const FEEDBACK_SENT = "Feedback Sent";
export const APPROVED = "Approved";
export const PROJECT = "project";
export const PROJECT_LOCKED_STATUS = "locked";
export const PROJECT_UNLOCKED_STATUS = "unlocked";

//Assessment
export const QUESTION = "question";
export const CORRECT_ANSWER = "correct";
export const INCORRECT_ANSWER = "incorrect";
export const EXPECTED_ANSWER = "expected";
export const NEED_HELP = "need-help";
export const WRONG_ANSWER = "wrong";
export const WRONG_OPTION_ID = 999;
export const FINAL = "final";
export const ASSESSMENT_PROGRESS_LOST_MESSAGE =
  "If you leave this assessment, your progress will be lost. Are you sure you want to leave?";
export const MAXIMUM_FINAL_ASSESSMENT_FAILED_ATTEMPTS = 3;

//Project Submission
export const PROGRAMMING_LANGUAGE = "programming_language";
export const TOTAL_PROJECTS_PER_COURSE = 2;

//OutLinks
export const MARKETING_TERMS_AND_CONDITIONS =
  BASE_MARKETING_URL + "/policies/terms_of_service/";
export const UNSCHOOL_INTERNSHIPS =
  "https://app6.talentrackr.com/Unschool/UnschoolHome.aspx";
export const UNSCHOOL_INTERNSHIPS_URL = "https://internships.unschool.in/";

// Project Submission Types
export const TEXT = "text";
export const FILE_UPLOAD = "file_upload";
export const CODE = "code";
export const URL = "url";

// Notifications
export const NOTIFICATION_BASE_COUNT = 20;

// Course Completion Status
export const COMPLETED_COURSES_STATUS = "completed-courses-status";

// Certificate type
export const COURSE = "course";

//Course card place holder image
export const CARD_PLACEHOLDER_IMAGE =
  "https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg";

//Course Types
export const COURSE_TYPE = "Course";
export const SKILL_COURSE_TYPE = "Kickstarter";

//Cart Url
export const CART_URL = BASE_CART_URL;

// Events
export const UPCOMING_EVENTS = "upcoming_events";
export const PAST_EVENTS = "past_events";

export const CATEGORIES = "categories";
export const BROWSE = "browse";
export const BROWSE_CATEGORY = "browse-category";
export const BROWSE_CATEGORY_PRODUCTLINE = "browse-category-productline";
export const UNSTOP = "unstop";
export const COURSES = "courses";
