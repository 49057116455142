import { ACTION_TYPES } from "@redux/actions/types";
import { intialJobCoursesState } from "../states";
const { SET_JOB_COURSES_LIST } = ACTION_TYPES;

const jobCourseListReducer = (state = intialJobCoursesState, action) => {
  switch (action.type) {
    case SET_JOB_COURSES_LIST:
      return { ...state, courses: action.payload };
    default:
      return state;
  }
};

export default jobCourseListReducer;
