import { ACTION_TYPES } from "@redux/actions/types";
import { initialCourseProgressState } from "@redux/states";
const { SET_COURSE_PROGRESS_DATA, UPDATE_COURSE_PROGRESS_DATA } = ACTION_TYPES;

let initialState = initialCourseProgressState;

const courseProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_PROGRESS_DATA:
      return action.payload;
    case UPDATE_COURSE_PROGRESS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default courseProgressReducer;
