import { isMobile, isTab } from "@helpers/global";
import { ALL_LESSONS_AND_ASSESSMENTS } from "@localization";

export const initialUserState = {
  isUserLoggedIn: false,
  userDetails: null,
};

export const initialHelperState = {
  isMobile: isMobile(),
  isTab: isTab(),
};

export const initialCourseListState = {
  courses: [],
};

export const initialCourseDetailsState = {
  course: {},
};

export const initialNoteListState = {
  notes: {},
  openAddNoteAction: false,
  notesFilterValue: ALL_LESSONS_AND_ASSESSMENTS,
};

export const initialProjectListState = {
  projects: {},
};

export const initialSyllabusListState = {
  chapters: {},
  activeChapterKey: null,
  activeLessonKey: null,
};

export const initialAssessmentState = {
  canonicalAssessment: null,
  page: 0,
  totalPages: null,
  questionList: null,
  showAssessmentMessage: false,
  answerResult: null,
  hasQuestionTimedOut: false,
  totalTimeRequired: null,
  assessmentFinalScore: null,
  assessmentFailedSubmissionsCount: 0,
};

export const initialCourseProgressState = null;

export const initialCourseFooterTabState = {
  activeTabKey: isMobile() || isTab() ? "Syllabus-0/.0" : "Notes-0/.0",
};

export const initialNotificationListState = {
  notifications: [],
  paginationDetails: null,
};

export const initialCategoryListState = {
  categories: [],
};

export const initialSkillCoursesState = {
  courses: [],
};

export const intialCourseTypeState = {
  typeName: null,
};

export const intialJobCoursesState = {
  courses: [],
};

export const initialUserPlansState = {
  plans: [],
};

export const initialLiveSessions = {
  meetings: [],
};

export const combinedInitialState = {
  user: initialUserState,
  helper: initialHelperState,
  courseList: initialCourseListState,
  courseDetails: initialCourseDetailsState,
  noteList: initialNoteListState,
  projectList: initialProjectListState,
  syllabusList: initialSyllabusListState,
  assessment: initialAssessmentState,
  courseProgress: initialCourseProgressState,
  courseFooterTab: initialCourseFooterTabState,
  notificationList: initialNotificationListState,
  categoryList: initialCategoryListState,
  skillCourseList: initialSkillCoursesState,
  jobCoursesList: intialJobCoursesState,
  courseType: intialCourseTypeState,
  userPlans: initialUserPlansState,
  liveSessions: initialLiveSessions,
};

export const statesToBePersisted = ["user"];
