import { ACTION_TYPES } from "@redux/actions/types";
import { initialSkillCoursesState } from "@redux/states";
const { SET_SKILL_COURSES_LIST } = ACTION_TYPES;

const initialState = initialSkillCoursesState;

const skillCourseListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SKILL_COURSES_LIST:
      return { ...state, courses: [...action.payload] };
    default:
      return state;
  }
};

export default skillCourseListReducer;
