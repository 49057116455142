import { ACTION_TYPES } from "@redux/actions/types";
import { initialNotificationListState } from "@redux/states";
const {
  SET_NOTIFICATIONS_LIST,
  SET_NOTIFICATION_PAGINATION_DETAILS,
  CLEAR_NOTIFICATION_LIST,
} = ACTION_TYPES;

let initialState = initialNotificationListState;

const notificationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_LIST:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_NOTIFICATION_PAGINATION_DETAILS:
      return {
        ...state,
        paginationDetails: action.payload,
      };
    case CLEAR_NOTIFICATION_LIST:
      const currentNotifications = action.payload;
      return {
        ...state,
        notifications: currentNotifications?.map((notification) => {
          return { node: { ...notification.node, isRead: true } };
        }),
      };
    default:
      return state;
  }
};

export default notificationListReducer;
