import { ACTION_TYPES } from "@redux/actions/types";
import { initialCategoryListState } from "@redux/states";
const { SET_CATEGORY_LIST } = ACTION_TYPES;

let initialState = initialCategoryListState;

const categoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
};

export default categoryListReducer;
