import { fas } from "@icons";
import { ERROR_SVG_PATH, PAGE_NOT_FOUND_SVG_PATH } from "@localization";

export const custom403Content = {
  pageTitle: "403 - FORBIDDEN ERROR",
  imgUrl: ERROR_SVG_PATH,
  errorHeading: "Uh oh, you’re in a restricted space.",
  errorContent:
    "Sorry, but you don’t have permission to access this page. If you need help, contact support.",
  toastMessage:
    "You don’t have permission to access this page. If you need help, contact support.",
};

export const courseFooterDesktopTabs = [
  "Notes",
  "Projects",
  // "Discussions",
  "About",
];

export const courseFooterMobileTabs = [
  "Syllabus",
  "Notes",
  "Projects",
  // "Discussions",
  "About",
  "Transcript",
  //"Coach",
];

export const skillCourseFooterDesktopTabs = ["Notes", "About"];

export const skillCourseFooterMobileTabs = [
  "Syllabus",
  "Notes",
  "About",
  "Transcript",
];

export const jobCourseFooterDesktopTabs = ["Notes", "Projects", "About"];

export const jobCourseFooterMobileTabs = [
  "Syllabus",
  "Notes",
  "Projects",
  "About",
  "Transcript",
];

export const courseSidebarTabs = ["Syllabus", "Transcript", "Coach"];

export const lessonIconEnum = {
  audio: fas.faVolumeUp,
  video: fas.faVideo,
  assessment: fas.faClipboardList,
  pdf: fas.faFileWord,
};

export const submissionIconEnum = {
  text: fas.faTextLeft,
  video: fas.faVideo,
  file_upload: fas.faFile,
  code: fas.faCode,
  url: fas.faLink,
};

export const drawerMenu = [
  {
    item: "Explore",
    subItems: [
      { item: "Technology" },
      { item: "Art & Design" },
      { item: "Management" },
    ],
  },
  { item: "My courses", link: "/" },
  { item: "Community", link: "/" },
  // { item: "Live Sessions", link: "/" },
  {
    item: "Internships",
    link: "/internships",
  },
  { item: "Notifications", link: "/notifications" },
];

export const UpgradePlanDataSource = [
  {
    feature: "Course Access",
    basic: true,
    advance: true,
    pro: true,
  },
  {
    feature: "Course Certification",
    basic: true,
    advance: true,
    pro: true,
  },
  {
    feature: "Quizzes & Assessments",
    basic: true,
    advance: true,
    pro: true,
  },
  {
    feature: "Minor Project",
    basic: true,
    advance: true,
    pro: true,
  },
  {
    feature: "Major Project",
    basic: false,
    advance: true,
    pro: true,
  },
  {
    feature: "Community",
    basic: false,
    advance: true,
    pro: true,
  },
  {
    feature: "Live Doubt Solving",
    basic: false,
    advance: true,
    pro: true,
  },
  {
    feature: "Mentorship",
    basic: false,
    advance: "Chat",
    pro: "Chat & Calls",
  },
  {
    feature: "Internship Opportunities",
    basic: false,
    advance: false,
    pro: "Unlimited",
  },
];

export const custom404Content = {
  pageTitle: "404 - PAGE NOT FOUND",
  imgUrl: PAGE_NOT_FOUND_SVG_PATH,
  errorHeading: "You may be lost...",
  errorContent:
    "Don’t worry, it’s just that the page you are looking for does not exist or has been moved. Double check the URL, else let’s get you back home.",
  buttonText: "Take me back home",
};

export const custom500Content = {
  pageTitle: "500 - INTERNAL SERVER ERROR",
  imgUrl: ERROR_SVG_PATH,
  errorHeading: "Uh oh, our server failed.",
  errorContent:
    "Sorry about that, we’re working on fixing the problem. Please try refreshing the page or try again later.",
  toastMessage:
    "We’re working on fixing the problem. Please try refreshing the page or try again later.",
};

export const states = [
  {
    code: "AN",
    value: "Andaman and Nicobar Islands",
  },
  {
    code: "AP",
    value: "Andhra Pradesh",
  },
  {
    code: "AR",
    value: "Arunachal Pradesh",
  },
  {
    code: "AS",
    value: "Assam",
  },
  {
    code: "BR",
    value: "Bihar",
  },
  {
    code: "CG",
    value: "Chandigarh",
  },
  {
    code: "CH",
    value: "Chhattisgarh",
  },
  {
    code: "DH",
    value: "Dadra and Nagar Haveli",
  },
  {
    code: "DD",
    value: "Daman and Diu",
  },
  {
    code: "DL",
    value: "Delhi",
  },
  {
    code: "GA",
    value: "Goa",
  },
  {
    code: "GJ",
    value: "Gujarat",
  },
  {
    code: "HR",
    value: "Haryana",
  },
  {
    code: "HP",
    value: "Himachal Pradesh",
  },
  {
    code: "JK",
    value: "Jammu and Kashmir",
  },
  {
    code: "JH",
    value: "Jharkhand",
  },
  {
    code: "KA",
    value: "Karnataka",
  },
  {
    code: "KL",
    value: "Kerala",
  },
  {
    code: "LD",
    value: "Lakshadweep",
  },
  {
    code: "MP",
    value: "Madhya Pradesh",
  },
  {
    code: "MH",
    value: "Maharashtra",
  },
  {
    code: "MN",
    value: "Manipur",
  },
  {
    code: "ML",
    value: "Meghalaya",
  },
  {
    code: "MZ",
    value: "Mizoram",
  },
  {
    code: "NL",
    value: "Nagaland",
  },
  {
    code: "OR",
    value: "Odisha",
  },
  {
    code: "PY",
    value: "Puducherry",
  },
  {
    code: "PB",
    value: "Punjab",
  },
  {
    code: "RJ",
    value: "Rajasthan",
  },
  {
    code: "SK",
    value: "Sikkim",
  },
  {
    code: "TN",
    value: "Tamil Nadu",
  },
  {
    code: "TS",
    value: "Telangana",
  },
  {
    code: "TR",
    value: "Tripura",
  },
  {
    code: "UP",
    value: "Uttar Pradesh",
  },
  {
    code: "UK",
    value: "Uttarakhand",
  },
  {
    code: "WB",
    value: "West Bengal",
  },
];

export const noteFilterOptions = [
  { value: "All lessons and assessments" },
  { value: "Current lesson or assessment" },
];

export const statusIconEnum = {
  passed: fas.faCheckCircle,
  failed: fas.faTimesCircle,
};

export const navLinks = [
  {
    name: "My courses",
    destination: "/",
    route: "/courses",
    nativeLink: false,
  },
  {
    name: "Community",
    destination: "/community",
    route: "/community",
    nativeLink: false,
  },
  {
    name: "Live Sessions",
    destination: "/events",
    route: "/live-sessions",
    nativeLink: false,
  },
  {
    name: "Internships",
    destination: "/internships",
    route: null,
    nativeLink: false,
  },
];

export const assessmentFinalScoreBoolEnum = {
  passed: true,
  failed: false,
};

export const productLines = [
  {
    title: "Internship Courses",
    slug: "internship-courses",
    variable: "internship_program",
  },
  {
    title: "Skill Courses",
    slug: "skill-courses",
    variable: "skill_courses",
  },
  { title: "Job Courses", slug: "job-courses", variable: "job_program" },
];
