import { ACTION_TYPES } from "@redux/actions/types";

import { initialUserPlansState } from "../states";

const { SET_USER_PLANS } = ACTION_TYPES;

const userPlanReducer = (state = initialUserPlansState, action) => {
  switch (action.type) {
    case SET_USER_PLANS:
      return { ...state, plans: action.payload };
    default:
      return state;
  }
};

export default userPlanReducer;
