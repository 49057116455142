import { ACTION_TYPES } from "@redux/actions/types";
import { initialCourseDetailsState } from "@redux/states";
const { SET_COURSE_DETAILS, UPDATE_COURSE_DETAILS } = ACTION_TYPES;

let initialState = initialCourseDetailsState;

const courseDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_DETAILS:
      return {
        ...state,
        course: action.payload,
      };
    case UPDATE_COURSE_DETAILS:
      return {
        ...state,
        course: { ...state.course, ...action.payload },
      };
    default:
      return state;
  }
};

export default courseDetailsReducer;
