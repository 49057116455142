import { ACTION_TYPES } from "@redux/actions/types";
import { initialProjectListState } from "@redux/states";
const { SET_PROJECT_LIST, UPDATE_PROJECT_LIST, UPDATE_PROJECT } = ACTION_TYPES;

let initialState = initialProjectListState;

const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_LIST:
      return {
        ...state,
        projects: action.payload,
      };
    case UPDATE_PROJECT_LIST:
      return {
        ...state,
        projects: { ...state.projects, [action.payload.id]: action.payload },
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        projects: { ...state.projects, [action.payload.id]: action.payload },
      };
    default:
      return state;
  }
};

export default projectListReducer;
