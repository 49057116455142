import { ACTION_TYPES } from "@redux/actions/types";
import { intialCourseTypeState } from "@redux/states";

const { SET_COURSE_TYPE } = ACTION_TYPES;

const courseTypeReducer = (state = intialCourseTypeState, action) => {
  switch (action.type) {
    case SET_COURSE_TYPE:
      return { ...state, typeName: action.payload };
    default:
      return state;
  }
};

export default courseTypeReducer;
