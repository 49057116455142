import { ACTION_TYPES } from "@redux/actions/types";
import { initialCourseFooterTabState } from "@redux/states";
const { SET_ACTIVE_COURSE_FOOTER_TAB } = ACTION_TYPES;

let initialState = initialCourseFooterTabState;

const courseFooterTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_COURSE_FOOTER_TAB:
      return {
        ...state,
        activeTabKey: action.payload,
      };
    default:
      return state;
  }
};

export default courseFooterTabReducer;
