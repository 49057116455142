import { ACTION_TYPES } from "@redux/actions/types";
import { initialLiveSessions } from "@redux/states";

const { SET_LIVE_SESSIONS } = ACTION_TYPES;

const liveSessionsReducer = (state = initialLiveSessions, action) => {
  switch (action.type) {
    case SET_LIVE_SESSIONS:
      return { meetings: action.payload };
    default:
      return state;
  }
};

export default liveSessionsReducer;
