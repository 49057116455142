import { ACTION_TYPES } from "@redux/actions/types";
import { initialCourseListState } from "@redux/states";
const { SET_COURSE_LIST, UPDATE_COURSE_LIST } = ACTION_TYPES;

let initialState = initialCourseListState;

const courseListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_LIST:
      return {
        ...state,
        courses: action.payload,
      };
    case UPDATE_COURSE_LIST:
      return {
        ...state,
        courses: [...state.courses, ...action.payload],
      };
    default:
      return state;
  }
};

export default courseListReducer;
