import { ACTION_TYPES } from "@redux/actions/types";
import { initialAssessmentState } from "@redux/states";
const {
  INCREMENT_ASSESSMENT_QUESTION_PAGE,
  DECREMENT_ASSESSMENT_QUESTION_PAGE,
  SET_ASSESSMENT_QUESTION_PAGE,
  RESET_ASSESSMENT_QUESTION_PAGE,
  SET_ASSESSMENT_TOTAL_PAGES,
  UPDATE_ASSESSMENT_STATE,
  SET_ASSESSMENT_QUESTION_LIST,
  SHOW_ASSESSMENT_MESSAGE,
  CLOSE_ASSESSMENT_MESSAGE,
  SET_ANSWER_RESULT,
  RESET_ANSWER_RESULT,
  SET_QUESTION_TIMEOUT_STATUS,
  SET_CANONICAL_ASSESSMENT,
  SET_ASSESSMENT_FINAL_SCORE,
  RESET_ASSESSMENT,
  SET_ASSESSMENT_FAILED_SUBMISSIONS_COUNT,
} = ACTION_TYPES;

let initialState = initialAssessmentState;

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_ASSESSMENT_QUESTION_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case DECREMENT_ASSESSMENT_QUESTION_PAGE:
      return {
        ...state,
        page: state.page - 1,
      };
    case SET_ASSESSMENT_QUESTION_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case RESET_ASSESSMENT_QUESTION_PAGE:
      return {
        ...state,
        page: 0,
      };
    case SET_ASSESSMENT_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case UPDATE_ASSESSMENT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ASSESSMENT_QUESTION_LIST:
      return {
        ...state,
        questionList: action.payload,
      };
    case SHOW_ASSESSMENT_MESSAGE:
      return {
        ...state,
        showAssessmentMessage: true,
      };
    case CLOSE_ASSESSMENT_MESSAGE:
      return {
        ...state,
        showAssessmentMessage: false,
      };
    case CLOSE_ASSESSMENT_MESSAGE:
      return {
        ...state,
        showAssessmentMessage: false,
      };
    case SET_ANSWER_RESULT:
      return {
        ...state,
        answerResult: action.payload,
      };
    case RESET_ANSWER_RESULT:
      return {
        ...state,
        answerResult: null,
        showAssessmentMessage: false,
      };
    case SET_QUESTION_TIMEOUT_STATUS:
      return {
        ...state,
        hasQuestionTimedOut: action.payload,
      };
    case SET_CANONICAL_ASSESSMENT:
      return {
        ...state,
        canonicalAssessment: action.payload,
      };
    case SET_ASSESSMENT_FINAL_SCORE:
      return {
        ...state,
        assessmentFinalScore: action.payload,
      };
    case SET_ASSESSMENT_FAILED_SUBMISSIONS_COUNT:
      return {
        ...state,
        assessmentFailedSubmissionsCount: action.payload,
      };
    case RESET_ASSESSMENT:
      return {
        ...initialAssessmentState,
        canonicalAssessment: state.canonicalAssessment,
        totalPages: state.totalPages,
        totalTimeRequired: state.totalTimeRequired,
        assessmentFailedSubmissionsCount:
          state.assessmentFailedSubmissionsCount,
      };
    default:
      return state;
  }
};

export default assessmentReducer;
