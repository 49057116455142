import { combineReducers } from "redux";
import assessmentReducer from "./assessmentReducer";
import categoryListReducer from "./categoryListReducer";
import courseDetailsReducer from "./courseDetailsReducer";
import courseFooterTabReducer from "./courseFooterTabReducer";
import courseListReducer from "./courseListReducer";
import courseProgressReducer from "./courseProgressReducer";
import courseTypeReducer from "./courseTypeReducer";
import helperReducer from "./helperReducer";
import jobCourseListReducer from "./jobCourseListReducer";
import liveSessionsReducer from "./liveSessionsReducer";
import noteListReducer from "./noteListReducer";
import notificationListReducer from "./notificationListReducer";
import projectListReducer from "./projectListReducer";
import skillCourseListReducer from "./skillCourseListReducer";
import syllabusListReducer from "./syllabusListReducer";
import userPlanReducer from "./userPlanReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
  user: userReducer,
  helper: helperReducer,
  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  noteList: noteListReducer,
  projectList: projectListReducer,
  syllabusList: syllabusListReducer,
  assessment: assessmentReducer,
  courseProgress: courseProgressReducer,
  courseFooterTab: courseFooterTabReducer,
  notificationList: notificationListReducer,
  categoryList: categoryListReducer,
  skillCourseList: skillCourseListReducer,
  courseType: courseTypeReducer,
  jobCoursesList: jobCourseListReducer,
  userPlans: userPlanReducer,
  liveSessions: liveSessionsReducer,
});

export default reducers;
