export const variants = {
  ctaButton: "cta",
  primaryButton: "primary",
  secondaryButton: "secondary",
  outlineDarkButton: "outlineDark",
  outlineLiteButton: "outlineLite",
  ghostButton: "ghost",
  positiveToastMessage: "positive",
  negativeToastMessage: "negative",
  warningToastMessage: "warning",
  error: "500",
  accessDenied: "403",
  textInput: "text",
  numberInput: "number",
  basicPlanType: "basic",
  advancePlanType: "advance",
  professionalPlanType: "professional",
  proPlanType: "pro",
  approvedMessage: "approved",
  feedbackMessage: "feedback",
  errorInlineMessage: "error",
  infoInlineMessage: "info",
  warningInlineMessage: "warning",
  authNavbar: "auth",
  courseNavbar: "course",
  neutralNavbar: "neutral",
  infoMessage: "info",
  successMessage: "success",
  errorMessage: "error",
  warningMessage: "warning",
  timeOutMessage: "timeout",
  lesson: "lesson",
  project: "project",
  underReview: "pending",
  feedbackSent: "feedback_sent",
  approved: "approved",
  seniorCoach: "senior_coach",
  juniorCoach: "junior_coach",
  learner: "learner",
  passed: "passed",
  failed: "failed",
  inProgress: "in_progress",
  finalAssessment: "final",
  midAssessment: "mid_chapter",
  completed: "completed",
  courseProgressBar: "course_progress_bar",
  skillCourseProgressBar: "skill_course_progress_bar",
  skillCourseNavbar: "skill_course_navbar",
  jobCourseNavbar: "job_course_navbar",
  jobCourseProgressBar: "job_course_progress_bar",
  courseBody: "course_body",
  courseSupportInfo: "course_support_info",
  courseFooter: "course_footer",
  skillCourseBody: "skill_course_body",
  skillCourseSupportInfo: "skill_course_support_info",
  skillcourseFooter: "skill_course_footer",
  jobCourseBody: "job_course_body",
  jobCourseSupportInfo: "job_course_support_info",
  jobCourseFooter: "job_course_footer",
  courseSyllabus: "course_syllabus",
  skillCourseSyllabus: "skill_course_syllabus",
  jobCourseSyllabus: "job_course_syllabus",
  upcomingEvents: "upcoming_events",
  pastEvents: "past_events",
  courseCompletedActions: "course_completed_actions",
  skillCourseCompletedActions: "skill_course_completed_actions",
  jobCourseCompletedActions: "job_course_completed_actions",
  created: "created",
  ended: "ended",
  browseNavbar: "browse",
};
