import { ACTION_TYPES } from "@redux/actions/types";
import { initialSyllabusListState } from "@redux/states";
import { cloneDeep, update } from "lodash";
const {
  SET_SYLLABUS_LIST,
  UPDATE_SYLLABUS_CHAPTER,
  UPDATE_SYLLABUS_LESSON,
  UPDATE_SYLLABUS_LIST,
  SET_ACTIVE_LESSON_KEY,
  SET_ACTIVE_CHAPTER_KEY,
} = ACTION_TYPES;

let initialState = initialSyllabusListState;

const syllabusListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYLLABUS_LIST:
      return {
        ...state,
        chapters: action.payload,
      };
    case UPDATE_SYLLABUS_LIST:
      return {
        ...state,
        chapters: { ...state.chapters, ...action.payload },
      };
    case UPDATE_SYLLABUS_CHAPTER:
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [action.payload.id]: {
            ...state.chapters[action.payload.id],
            ...action.payload,
          },
        },
      };
    case UPDATE_SYLLABUS_LESSON:
      const chapterDeepClone = cloneDeep(state.chapters);
      return {
        ...state,
        chapters: update(
          chapterDeepClone,
          `[${action.payload.chapter.id}].lessons[${action.payload.id}]`,
          (lesson) => {
            return {
              ...lesson,
              ...action.payload,
            };
          }
        ),
      };
    case SET_ACTIVE_CHAPTER_KEY:
      return {
        ...state,
        activeChapterKey: action.payload,
      };
    case SET_ACTIVE_LESSON_KEY:
      return {
        ...state,
        activeLessonKey: action.payload,
      };
    default:
      return state;
  }
};

export default syllabusListReducer;
