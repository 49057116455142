import { ACTION_TYPES } from "@redux/actions/types";
import { initialNoteListState } from "@redux/states";
import { omit } from "lodash";
const {
  SET_NOTE_LIST,
  UPDATE_NOTE_LIST,
  UPDATE_NOTE,
  DELETE_NOTE,
  DISCARD_ADD_NOTE_ACTION,
  OPEN_ADD_NOTE_ACTION,
  SET_NOTES_FILTER,
} = ACTION_TYPES;

let initialState = initialNoteListState;

const noteListReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ADD_NOTE_ACTION:
      return {
        ...state,
        openAddNoteAction: true,
      };
    case DISCARD_ADD_NOTE_ACTION:
      return {
        ...state,
        openAddNoteAction: false,
      };
    case SET_NOTE_LIST:
      return {
        ...state,
        notes: action.payload,
      };
    case UPDATE_NOTE_LIST:
      return {
        ...state,
        notes: { ...state.notes, [action.payload.id]: action.payload },
      };
    case UPDATE_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.id]: {
            ...state.notes[action.payload.id],
            ...action.payload,
          },
        },
      };
    case DELETE_NOTE:
      return {
        ...state,
        notes: omit(state.notes, [action.payload.id]),
      };
    case SET_NOTES_FILTER:
      return {
        ...state,
        notesFilterValue: action.payload,
      };
    default:
      return state;
  }
};

export default noteListReducer;
